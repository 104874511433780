import styles from 'css/src/exports.module.scss';

import type { Map } from 'immutable';
import Immutable from 'immutable';
import type { MediaSourceData } from '@lumen5/luminary';
import { VideoData } from '@lumen5/luminary';

import QueryString from './querystring';

import {
    LanguageEnum,
    LicenseEnum,
    NarrativeThreadEnum,
    VideoApprovalStatusEnum,
    VideoStatus
} from 'js/src/client';
import { convertRemToPx, stripCssUnits } from 'js/src/libs/custom-utils';

export const MAX_SCENE_GROUPS_IN_VIDEO = 100;

// Script composer consts
export const MAX_SENTENCES_FOR_USE_ALL_SENTENCES = 40;
export const MIN_WORDS_PER_EDITED_SCRIPT_BLOCK = 15;
export const WORD_DURATION_ESTIMATE_SECONDS = 0.307;

// The subgroup of video statuses corresponding to 'rendering'
export const RENDERING_STATUSES = [
    VideoStatus.QUEUED,
    VideoStatus.PROCESSING,
    VideoStatus.FAILED,
];

export const FREE_PLAN_NAME = 'Free'; // derived from `web/models.py`: `get_plan_name_for_analytics`

// Derived from the web/pricing/pricing_plans.py
export const PROMOTION_PLANS_DISPLAY_NAMES = [
    'Promo Plan 2020',
    'AppSumo Designer Plan 2021',
];
export const FREE_PLAN_DISPLAY_NAME = 'Community';

// Pricing plan API name prefixes
export const PLAN_PREFIX = {
    BUSINESS: 'business',
    STARTER: 'starter',
    ENTERPRISE: 'team',
    PREMIUM: 'premium',
    PRO: 'pro',
};

export const PRETTY_PLAN_NAMES_FOR_WEBSITE_COPY = {
    UPGRADE_LEVEL_1: 'Basic',
    UPGRADE_LEVEL_2: 'Starter',
    UPGRADE_LEVEL_3: 'Professional',
};

export function GET_VALID_VIDEO_MIME_TYPES(): string[] {
    return [
        'video/quicktime', 'image/mov', 'video/x-quicktime', 'video/webm', 'video/webm;codecs=h264', 'video/mp4',
        'video/x-m4v', 'video/x-msvideo', 'video/3gpp', 'video/x-flv', 'video/x-mkv',
        'video/x-matroska', 'video/avi', 'video/mpeg',
    ] as const;
}

export function GET_VALID_ICON_MIME_TYPES(): string[] {
    return [
        'image/svg+xml', 'application/json',
    ] as const;
}

export function GET_VALID_IMAGE_MIME_TYPES(only_images: boolean): string[] {
    const valid_images = [
        'image/png', 'image/jpeg', 'image/jpg', 'image/bmp', 'image/x-windows-bmp',
        'image/x-ms-bmp', 'image/webp'];

    if (!only_images) {
        valid_images.push('image/gif');
    }
    return valid_images;
}

export function GET_VALID_AUDIO_MIME_TYPES(): string[] {
    return [
        'audio/mp3', // Chrome's mp3 mime type
        'audio/mpeg', // Safari's mp3 mime type
        'audio/webm', // Chrome/Opera media recorder default
        'audio/ogg', // Firefox media recorder default
        'audio/mp4', // Safari's media recorder default
        'audio/x-m4a', // m4a mime type
        'audio/wav', // wav mime type
        'audio/flac', // flac, open source audio codec
    ] as const;
}

export const noop: (...args: any[]) => any = () => { /* do nothing */ };
export const noopPromise = async() => { /* do nothing */ };

export enum PUBLISH_OPTION {
    FACEBOOK_STORY_EACH_SLIDE = 'FACEBOOK_STORY_EACH_SLIDE',
    SHARE_PAGE_EXP = 'SHARE_PAGE_EXP',
}

export const PLACEHOLDER_IMAGE_URL = 'https://storage.googleapis.com/lumen5-site-images/story-placeholder.jpg';

export const AUTO_CROP = true;
export const DONT_AUTO_CROP = false;
export const AUTO_BRIGHTNESS = true;
export const DONT_AUTO_BRIGHTNESS = false;

export const ERROR_CODES = {
    PREMIUM_MEDIA_CREDITS: 'premium-media-credits',
    HIT_MEDIA_CREDIT_LIMIT: 'hit-media-credit-limit',
    UPGRADE_FOR_UNLIMITED_MEDIA: 'upgrade-for-unlimited-media',
    VIDEO_DURATION_TOO_LONG: 'video-duration-too-long',
    VIDEO_HAS_TOO_MANY_SCENES: 'video-has-too-many-scenes',
    TRANSITIONED_MEDIA_PRESENT: 'transitioned-media-present',
    OVERWRITING_DATA: 'overwriting-data',
    ALREADY_PUBLISHED: 'already-published',
    VOICEOVER_TOO_LONG: 'voiceover-too-long',
    MAX_SAVED_TEMPLATES_HIT: 'max-saved-templates-hit',
    CUSTOM_FONTS_NOT_ALLOWED: 'custom-fonts-not-allowed',
    CUSTOM_COLORS_NOT_ALLOWED: 'custom-colors-not-allowed',
    CUSTOM_COLORS_AND_FONTS_NOT_ALLOWED: 'custom-colors-and-fonts-not-allowed',
};

// `copy` data
export const COPY_CONTENT = {
    NOT_ENOUGH_DATA: 'Not enough data',
};

// Define in what context the SINGLE_FILE_UPLOAD_SECTION is used
export const SINGLE_FILE_UPLOADER_USAGE_CONTEXT_OPTIONS = {
    WATERMARK: 'Watermark media asset',
    OUTRO_SLIDE: 'Outro media asset', // DEPRECATED, to be merged with MEDIA_ASSET
    MEDIA_ASSET: 'Media asset',
};

export const PROFILE_PLACEHOLDER_IMAGE_URL = 'https://storage.googleapis.com/lumen5-site-images/profile-placeholder.png';
export const RECENT_TEMPLATES_CATEGORY = -1;
export const SAVED_TEMPLATES_CATEGORY = -2;

export type TemplateCategoryId = number;

export const CHECKOUT_SESSION_SOURCE_PRICING = 'pricing';
export const CHECKOUT_SESSION_SOURCE_ACCOUNT_BILLING = 'account-billing';

export const COOKIE_KEYS = {
    VOICEOVER_INSTRUCTION: 'voiceover_instruction',
    MEDIA_FILTER: 'media-filter',
    MEDIA_FILTER_RESET: 'media-filter-reset',
    LAST_VISITED_PROJECT: 'last_visited_project',
    FEEDBACK_TEXT_AI_SHOWN: 'feedback_text_ai_shown',
    FEEDBACK_VOICEOVER_AI_SHOWN: 'feedback_voiceover_ai_shown',
    CAPTIONS: 'captions',
    VIDEO_LIMIT_WARNING: 'video_limit_warning',
    AI_VOICEOVER_DEFAULT_VOICE_PRESET: 'ai_voiceover_default_voice_preset',
    LAST_SELECTED_SCRIPT_SOURCE: 'last_selected_script_source',
    LAST_SELECTED_SCRIPT_DURATION_TARGET: 'last_selected_script_duration_target',
    VIDEO_DURATION_LIMIT_BANNER_DISMISSED: 'video_duration_limit_banner_dismissed',
    THIRD_PARTY_COOKIES_CONSENT: 'third_party_cookies_consent',
    SAVED_TEMPLATE_BLUEPRINT_REBRAND_CALLOUT_DISMISSED: 'saved_template_blueprint_rebrand_callout_dismissed',
    VOICEOVER_EXPLANATION_DISMISSED: 'voiceover_explanation_dismissed',
    GENERATIVE_MEDIA_FIND_RECENTS_CALLOUT_DISMISSED: 'generative_media_find_recents_callout_dismissed',
    SAVED_TEMPLATE_BLUEPRINT_REBRAND_BANNER_DISMISSED: 'saved_template_blueprint_rebrand_banner_dismissed',
    RECENTLY_USED_SAVED_TEMPLATE_IDS: 'recently_used_saved_template_ids',
    CUSTOM_TEMPLATE_BANNER_DISMISSED: 'custom_template_banner_dismissed',
};

export const VIDEO_APPROVAL_STATUS_DISPLAY_NAMES: Record<string, any> = {
    [VideoApprovalStatusEnum.NEEDS_REVIEW]: {
        text: 'Needs review',
        picture_url: 'https://upload.wikimedia.org/wikipedia/commons/2/24/Missing_avatar.svg',
        badgeTheme: 'warning',
    },
    [VideoApprovalStatusEnum.IN_PROGRESS]: {
        text: 'In progress',
        picture_url: 'https://upload.wikimedia.org/wikipedia/commons/2/24/Missing_avatar.svg',
        badgeTheme: 'info',
    },
    [VideoApprovalStatusEnum.APPROVED]: {
        text: 'Approved',
        picture_url: 'https://upload.wikimedia.org/wikipedia/commons/2/24/Missing_avatar.svg',
        badgeTheme: 'success',
    },
};

export enum NEW_VIDEO_FLOW_CREATION_METHOD {
    TEMPLATE = 'template',
    SKIP = 'skip',
    SAVED_TEMPLATE = 'saved template',
    THEME = 'theme',
    TEMPLATE_PREVIEW_MODAL = 'template preview modal',
}
export const TEMPLATE_PAGE_COLLAPSED_HEIGHT = stripCssUnits(styles['template-page-collapsed-height']);
export const TEMPLATE_TILE_MAX_WIDTH = convertRemToPx(styles['template-tile-max-width']);
export const TEMPLATE_TILE_COLUMN_GAP = convertRemToPx(styles['template-tile-column-gap']);

export const CUSTOMER_BLUEPRINT_TILE_COLUMN_GAP = convertRemToPx(styles['customer-blueprint-tile-column-gap']);
export const CUSTOMER_BLUEPRINT_TILE_MAX_WIDTH = convertRemToPx(styles['customer-blueprint-tile-width']) + CUSTOMER_BLUEPRINT_TILE_COLUMN_GAP;
// height of tile plus height of header & gap
export const CUSTOMER_BLUEPRINT_LIST_COLLAPSED_HEIGHT = stripCssUnits(styles['customer-blueprint-tile-height']) + 33;


export const DEPRECATED_INVISIBLE_URL = 'https://storage.googleapis.com/lumen5-prod-images/invisib.png';
export const OUTRO_DUMMY_URL = 'https://storage.googleapis.com/lumen5-site-images/dummy-image.png';

// Currently when the user clicks the button they would only purchase 1 seat
export const DEFAULT_SEAT_PURCHASE_QUANTITY = 1;

// USER_CHURN_REASONS are used to define reasons which could have prevented users from using lumen5.com.
export const USER_CHURN_REASONS = {
    PENDING_PAYMENT_CONFIRMATION: 'pending_payment_confirmation',
    PENDING_TEAM_SIZE_ADJUSTMENT: 'pending_team_size_adjustment',
};

export const HUBSPOT_MEETING_SCRIPT_TAG_SRC = 'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js';
export const HUBSPOT_MEETING_SCRIPT_TAG_ID = 'hubspot-meeting-script-tag-id';


export const MFA_BACKUP_KEYS_SCREEN_MESSAGE = `These backup codes will be required if you are no longer able to access the authenticator app. Previous backup codes will no longer work.`;

export const BRAND_KITS = {
    MIN_COLORS: 3,
    MAX_COLORS: 10,
    NAME_CHAR_LIMIT: 35,
};

export const LOADING_SCENE_MSG = {
    CREATING_SCENE: 'Creating scene...',
    LOADING_FONT: 'Loading font...',
    GEN_CAPTION: 'Generating captions...',
    OVERLAYS: 'The bots are working hard to make your video.',
    ONBOARDING: 'Generating preview...',
};

export const MEDIA_ZOOM = {
    MIN_VALUE: 0.1,
    MAX_VALUE: 3,
    STEP: 0.01,
};

export const MEDIA_ROTATION = {
    MIN_VALUE: -90,
    MID_VALUE: 0,
    MAX_VALUE: 90,
    STEP: 1,
};

export const MEDIA_CROP_MARGIN_PX = 10;

const WORDS_PER_MIN = 160;
const SECONDS_PER_WORD = 60 / WORDS_PER_MIN;
const WORDS_PER_CAPTION = 9;
export const CAPTION_CHUNK_OPTIONS = {
    WORDS_PER_MIN: WORDS_PER_MIN, // roughly, but this should also probably be determined from the transcript
    SECONDS_PER_WORD: SECONDS_PER_WORD,
    WORDS_PER_CAPTION: WORDS_PER_CAPTION, // empirical observation of captions in the wild
    TIME_PER_CAPTION: WORDS_PER_CAPTION * SECONDS_PER_WORD,
    MIN_CHUNK_DURATION: 1,
    KEEP_GOING_MAX_WORDS: 3, // determines how many words over-length a caption can go if it means preserving sentence boundaries
    STOP_EARLY_MIN_WORDS: 5,
    MAX_BOUNDARY_WORD_DURATION: 1, // Maximum duration in seconds that the start or end word in a transcript can be
    SCENE_SENTENCE_BOUND_THRESHOLD: 2, // Maximum index difference that we can have between a sentence and a scene bound
};

export enum DROPDOWN_ITEM_TEXT_STYLES {
    MUTED = 'muted',
}

export const TRANSCRIPT_CONFIG = {
    LINEBREAK_PAUSE_THRESHOLD_SECONDS: 0.4,
    MAX_PARAGRAPH_SENTENCE_COUNT: 3,
    MAX_TEXT_CORRECT_WORDS: 3,
    MAX_TEXT_CORRECT_NEW_WORDS: 3,
    AUTO_INSERT_START_OFFSET_MILLIS: 4000,
    AUTO_INSERT_DURATION_MILLIS: 5000,
    // The duration threshold when we generate a silence node
    // test-transcript-words has a silence of 1000 ms so it is set to 1001 ms to avoid generating the silence node
    SILENCE_NODE_THRESHOLD_MILLIS: 1001,
    SILENCE_NODE_TARGET_DURATION_MILLIS: 1000,
    MAX_SILENCE_NODES: 9, // Each node is 1s = max duration of 9s
};

export const SHARE_PAGE_NOTIFICATION = {
    RESIZE: 'resize',
    TRANSLATION: 'translation',
};

export const ILLUSTRATION_COLOR_NUMBER = 7; // number of color when we consider icon as illustration and do not allow change colors on it
export const SIMPLE_ICON_COLOR_THRESHOLD = 2; // we consider icon as simple less than this number of colors

export enum CREATOR_MODE {
    VIDEO_EDITOR = 'video_editor',
    SAVED_TEMPLATE_EDITOR = 'saved_template_editor',
}

export enum SCENE_DURATIONS {
    MIN = 3400,
    MAX = 7000,
    DEFAULT = 4500,
    OUTRO = 5000,
    BRAND = 4053,
}

export const SCRIPT_OPTIONS_TONES = [
    {
        label: 'Match my content',
        value: '',
    },
    {
        label: 'Friendly',
        value: 'friendly',
    },
    {
        label: 'Relaxed',
        value: 'relaxed',
    },
    {
        label: 'Professional',
        value: 'professional',
    },
    {
        label: 'Bold',
        value: 'bold',
    },
    {
        label: 'Adventurous',
        value: 'adventurous',
    },
    {
        label: 'Witty',
        value: 'witty',
    },
    {
        label: 'Persuasive',
        value: 'persuasive',
    },
    {
        label: 'Empathetic',
        value: 'empathetic',
    },
];

// mirrors the SourceType enum in media/enums.py
export enum SourceType {
    SOURCE_TYPE_IMAGE = 0,
    SOURCE_TYPE_VIDEO = 1,
    SOURCE_TYPE_AUDIO = 2,
    SOURCE_TYPE_ICON = 3,
}

export const OverlaySelectorLuminaryConfigOverrides = () => {
    // this is an exported method so that we can override it in tests
    return {
        name: 'overlaySelector',
        mediaSizeToUse: VideoData.THUMBNAIL_MEDIA_SIZE,
        resolutionValue: 220,
        fetchResources: {
            media: true,
            fonts: true,
            transitions: false,
            audio: false,
        },
    };
};

export const FullscreenSceneSelectorLuminaryConfigOverrides = () => {
    // this is an exported method so that we can override it in tests
    return {
        name: 'fullscreenSceneSelector',
        resolutionValue: 220,
        fetchResources: {
            media: true,
            fonts: true,
            audio: false,
            transitions: false,
        },
    };
};

/* TYPESCRIPT HELPERS */
export type ValueOf<T> = T[keyof T];

// immutable type helpers
export type RecursiveImmutableMap<T> =
    // guard: if it's an ImmutableMap, return that type
    T extends ImmutableMap<any> ?
        T :
        // guard: if it's an Immutable.Map, return that type
        T extends Immutable.Map<any, any> ?
            T :
            // guard: if it's an Immutable.List, return that type
            T extends Immutable.List<any> ?
                T :
                // guard: if it's an object that extends HTMLElement, return that type (not serialized by Immutable)
                T extends HTMLElement ?
                    T :
                    // tweak: if it's a React.ComponentType, return it in ImmutableMap
                    T extends React.ComponentType<unknown> ?
                        ImmutableMap<T> :
                        // tweak: if it's an object array, return it modified to be an immutable list
                        T extends Array<object> ?
                            Immutable.List<ImmutableMap<T[0]>> :
                            // guard: if it's an array (non-object), return that type in an immutable list
                            T extends Array<any> ?
                                Immutable.List<T[0]> :
                                // tweak: if it's an object, return it modified to be an immutable map
                                T extends object ?
                                    // tweak: if it's a function, return a plain function (no such thing as an "ImmutableFunction")
                                    T extends (...args: unknown[]) => any ?
                                        T :
                                        ImmutableMap<T> :
                                    T;
export type DeImmutablizeObject<T> =
    // tweak: if it's an ImmutableMap, return the wrapped type
    T extends ImmutableMap<infer ElementType> ?
        ElementType :
        // tweak: if it's an Immutable.List wrapping an ImmutableMap, return the wrapped type
        T extends Immutable.List<ImmutableMap<infer ElementType>> ?
            ElementType[] :
            // tweak: if it's an Immutable.List, return the wrapped type
            T extends Immutable.List<infer ElementType> ?
                ElementType[] :
                // else, return object
                    T;


/*
CommonKeys is used to get the keys of a union type.

Typescript will return never for:
keyof (A | B) // never

But we can get the shared keys of A and B by using:
CommonKeys<A | B>
*/
type CommonKeys<T> = T extends T ? keyof T : keyof T;
/**
 * The following type can be used to check the keys inside an Immutable.Map<K,V>. ImmutableJS only captures generic types,
 * but this implementation utilizes keyof which exposes the keys inside an Immutable.Map to typescript.
 *
 * Example usage:
 * type Comment = { message: string; }
 * type CommentStore = ImmutableMap<Comment>
 *
 * (comment:CommentStore).get('message') // infers string type
 * (comment:CommentStore).get('content') // will throw error because Comment doesn't have key "content"
 *
 * NOTE: nested lists & objects created by `fromJS()` are themselves immutable objects. But types usually won't define
 * them as such. For example:
 *      type Video = {
 *          scenes = Scene[]
 *      }
 * If we were to just do `ImmutableMap<Video>.get('scenes')` the return type would be `Scene`. But it's actually
 * an immutable list!
 * So we've hooked up `RecursiveImmutableMap` to detect lists & objects and return them as immutable objects. yay!
 *
 * This covers 99% of cases but it is possible to create an immutable object which has non-immutable children that are
 * lists or objects. In this case we'll erroneously type them as possibly Immutable. You'll need to typecast them!
 */
export type ImmutableMap<JsObject extends object> = Omit<Map<CommonKeys<JsObject>, JsObject>, 'set' | 'delete' | 'setIn' | 'deleteIn' | 'update' | 'get' | 'toJS'> & {
    set: <AKeyOfThatJsObject extends keyof JsObject>(key: AKeyOfThatJsObject, value: RecursiveImmutableMap<ValueOf<Pick<JsObject, AKeyOfThatJsObject>>>) => ImmutableMap<JsObject>;
    delete: <AKeyOfThatJsObject extends keyof JsObject>(key: AKeyOfThatJsObject) => ImmutableMap<JsObject>;
    update: <AKeyOfThatJsObject extends keyof JsObject>(key: AKeyOfThatJsObject, updater: (value: ValueOf<Pick<JsObject, AKeyOfThatJsObject>>) => ValueOf<Pick<JsObject, AKeyOfThatJsObject>>) => ImmutableMap<JsObject>;
    get: <AKeyOfThatJsObject extends keyof JsObject>(key: AKeyOfThatJsObject, defaultVal?: unknown) => RecursiveImmutableMap<ValueOf<Pick<JsObject, AKeyOfThatJsObject>>>;
    toJS: () => DeImmutablizeObject<JsObject>;

    // the following is not the most accurate because there is no easy way to type check if the keyPath exists in the nested object
    // but should at least type check the return types
    setIn: (keyPath: (string|number|symbol)[], newValue: unknown) => ImmutableMap<JsObject>;
    deleteIn: (keyPath: (string|number|symbol)[]) => ImmutableMap<JsObject>;
}

export const ImmutableFromJS = <T>(obj: T) => {
    return Immutable.fromJS(obj) as RecursiveImmutableMap<T>;
};

export const ImmutableToJS = <T extends ImmutableMap<any> | Immutable.List<any>>(immutableObj: T) => {
    return immutableObj.toJS() as DeImmutablizeObject<T>;
};

export const ImmutableIsMap = (obj: any): obj is ImmutableMap<any> => {
    return Immutable.isMap(obj);
};

export type NoOwnProps = Record<never, never>;
export type NoOwnState = Record<never, never>;

export const INVISIBLE_IMAGE_SOURCE_DATA: MediaSourceData = {
    'type': 'image',
    'title': 'Invisible',
    'uuid': '385d4e2f-8b88-41ed-959f-8e8f9da93bba',
    'source': 21,
    'source_id': 'invisible',
    'license': LicenseEnum.CC0,
    'is_editorial': false,
    'width': 500,
    'height': 500,
    'thumbnail_url': 'https://storage.googleapis.com/lumen5-prod-images/invisible.png',
    'preview_url': 'https://storage.googleapis.com/lumen5-prod-images/invisible.png',
    'render_url': 'https://storage.googleapis.com/lumen5-prod-images/invisible.png',
};

export const UNKNOWN_LANGUAGE_CODE = LanguageEnum.XX;

export enum HTTP_STATUS_CODES {
    FORBIDDEN = 403,
    NOT_FOUND = 404,
}

// Avoid changing these values as they are serialized and saved to the database for each video.
export enum SCRIPT_CREATION_SOURCES {
    URL = 'Import an article',
    DOCUMENT = 'Upload a PDF',
    IDEA = 'Outline an Idea',
    PASTE = 'Paste text',
}

export enum AudioVolume {
    MIN = 0,
    MAX = 100,
    DUCK = 15
}

/**
 * Sometimes we have an enum value but we want the key. For example, for SourceEnum we might have a value of 0
 * and what we want is the key 'SOURCE_USER'. This method will return the key for a given enum value.
 */
export function getEnumKeyByEnumValue(myEnum: Record<string, unknown>, enumValue: unknown): string {
    const keys = Object.keys(myEnum).filter(x => myEnum[x] === enumValue);
    return keys.length > 0 ? keys[0] : '';
}

/**
 * A helpful filter function to use in a .filter() call to filter out undefined values,
 * and tell typescript what you have left in an array. For example:
 *
 * const arr = [1, undefined, 2, undefined, 3];
 * const filteredArr = arr.filter(isNotUndefined); // filteredArr is number[] automatically
 */
export function isNotUndefined<T>(a: T | undefined): a is T {
    return a !== undefined;
}

export function isUndefined<T>(a: T | undefined): a is undefined {
    return a === undefined;
}
export function isNotNull<T>(a: T | null): a is T {
    return a !== null;
}

// Advanced media controls
export const DEFAULT_BRIGHTNESS = 1;
export const DEFAULT_CONTRAST = 1;
export const DEFAULT_HUE = 0;
export const DEFAULT_SATURATION = 0;
export const DEFAULT_LIGHTNESS = 0;
export const DEFAULT_BLUR = 0;
export const DEFAULT_VIGNETTING = 0;
export const DEFAULT_VIGNETTING_BLUR = 0.3;
export const DEFAULT_VIGNETTING_ALPHA = 1;
export const DEFAULT_NOISE = 0;
export const DEFAULT_NOISE_SIZE = 1;
export const DEFAULT_ENABLE_GREYSCALE = false;
export const DEFAULT_PLAYBACK_RATE = 1;

export function isUserLoggedIn() {
    return window.SERVER && window.SERVER.USER !== null && window.SERVER.ANALYTICS !== null;
}


export class ApiError extends Error {
    body: unknown;
    url: string;
    status: number;

    constructor(status: number, body: unknown, url: string) {
        super(`ApiError ${status}`);
        this.body = body;
        this.status = status;
        this.url = url;
    }
}

export const isCreatorSavedTemplateMode = () => Object.prototype.hasOwnProperty.call(QueryString, 'saved_template');

export const COLOR_L5_PRIMARY_100 = styles['allColors-color-l5-primary-100'];
export const COLOR_L5_PRIMARY_200 = styles['allColors-color-l5-primary-200'];
export const COLOR_L5_PRIMARY_300 = styles['allColors-color-l5-primary-300'];
export const COLOR_L5_PRIMARY_400 = styles['allColors-color-l5-primary-400'];
export const COLOR_L5_PRIMARY_500 = styles['allColors-color-l5-primary-500'];
export const COLOR_L5_PRIMARY_600 = styles['allColors-color-l5-primary-600'];
export const COLOR_L5_PRIMARY_700 = styles['allColors-color-l5-primary-700'];
export const COLOR_L5_PRIMARY_800 = styles['allColors-color-l5-primary-800'];
export const COLOR_L5_PRIMARY_900 = styles['allColors-color-l5-primary-900'];

export const COLOR_L5_GREY_600 = styles['allColors-color-grey-600'];

// Since Saved Templates are not actual Blueprints yet, we need to be able to get the narrative thread of the child video
// in the same way we would for a real Blueprint. This function is based on get_core_narrative_thread_for_video from blueprint_utils.py
export const getCoreNarrativeThreadForVideo = (narrativeThreads: NarrativeThreadEnum[]) => {
    if (narrativeThreads.includes(NarrativeThreadEnum.VOICEOVER)) {
        return NarrativeThreadEnum.VOICEOVER;
    }
    if (narrativeThreads.includes(NarrativeThreadEnum.TALKING_HEAD)) {
        return NarrativeThreadEnum.TALKING_HEAD;
    }
    return NarrativeThreadEnum.TEXT;
};

export const NARRATIVE_THREAD_ICON_MAP: Record<NarrativeThreadEnum, string> = {
    [NarrativeThreadEnum.TEXT]: 'view_timeline',
    [NarrativeThreadEnum.TALKING_HEAD]: 'person',
    [NarrativeThreadEnum.VOICEOVER]: 'mic',
};

export const NARRATIVE_THREAD_DISPLAY_STRING_MAP: Record<NarrativeThreadEnum, string> = {
    [NarrativeThreadEnum.TEXT]: 'Text on media',
    [NarrativeThreadEnum.TALKING_HEAD]: 'Talking head',
    [NarrativeThreadEnum.VOICEOVER]: 'Voiceover',
};

export const MAX_RECENTLY_USED_SAVED_TEMPLATES = 4;

export const BLUEPRINTS_LEARN_MORE_URL = 'https://help.lumen5.com/en/article/blueprints-ea25sk/';

export const AI_CONTEXT_CHAR_LIMITS = {
    input: 50,
    area: 600,
};
