const QueryString: Record<string, string> = (function() {
    // This function is anonymous, is executed immediately and
    // the return value is assigned to QueryString!
    const query_string: Record<string, string> = {};
    const query = window.location.search.substring(1);
    const vars = query.split('&');
    for (let i = 0; i < vars.length; i++) {
        const pair = vars[i].split('=');

        const key = pair[0];
        const value = decodeURIComponent(pair.slice(1).join('='));

        if (typeof query_string[key] === 'undefined') {
            query_string[key] = value;
        // If second+ entry with this name, error
        }
        else {
            // eslint-disable-next-line no-console
            console.error('Duplicate query string key: ' + key);
        }
    }
    return query_string;
}());


export default QueryString;
